import useFunnelStore from "~/stores/funnel";

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return

  const store = useFunnelStore()

  if (store.countCats === 1) {
    if (store.currentCatIndex === 0) {
      return navigateTo('/funnel/generate');
    }
  }

  if (store.countCats === 2) {
    if (store.currentCatIndex === 0) {
      store.currentCatIndex = 1;
      if (store.cats.length === 1) {
        store.addCat();
      }

      return navigateTo('/funnel/name?currentCatIndex=1');
    }

    if (store.currentCatIndex === 1) {
      return navigateTo('/funnel/generate');
    }
  }

  if (store.countCats === 3) {
    if (store.currentCatIndex + 1 < store.cats.length) {
      return navigateTo(`/funnel/name?currentCatIndex=${store.currentCatIndex+1}`);
    }

    if (store.currentCatIndex === 0 || store.currentCatIndex === 1) {
      store.currentCatIndex = store.currentCatIndex + 1;
      if (store.cats.length === store.currentCatIndex) {
        store.addCat();
      }

      return navigateTo(`/funnel/name?currentCatIndex=${store.currentCatIndex}`);
    }
  }
})